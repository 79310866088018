import React from "react"
import Layout from "../components/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Card, CardBody, CardSubtitle, Badge } from "reactstrap"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import { slugify } from "../utils/utilityFunctions"
import PageWithComments from "../components/comments"


const SinglePost = ({ data, pageContext }) => {
  /*useEffect(()=>{
    window.scrollTo({ behavior: 'smooth', top: 450 })
  })

  */

  const post = data.markdownRemark.frontmatter
  const baseUrl = 'https://www.highvoltagecode.com/'

  //REMOVING DISQUS UNTIL THEY FIX THEIR SAMESITE COOKIES STUFF (BREAKING LINKS)
  /*const disqusShortname = 'highvoltagecode'
  const disqusConfig = {
    identifier:  data.markdownRemark.id,
    title: post.title,
    url: baseUrl + pageContext.slug,

  }
  //console.log("THE CONSOLE LOG", post.seo)
  */
  return (

    <Layout pageTitle={post.title}>
      <SEO title={post.title} keywords={post.seo} description={data.markdownRemark.excerpt} />
      <Card>
        <CardBody style={{ margin: "20px" }}>
          <div className="border-after padded">
            {/*
          <h4>
            <CardTitle className="text-center">{post.title}</CardTitle>
          </h4>
  */}
            <CardSubtitle className="text-center">
              <span>Posted on {post.date}</span> by{" "}
              <span className="text-info">{post.author}</span>
              <div className="justify">

                <ul className="post-tags">
                  {post.tags.map(tag => {
                    return (
                      <li key={tag}>
                        <Link to={`/tag/${slugify(tag)}`} />
                        <Badge color="primary">{tag}</Badge>
                      </li>
                    )
                  })}
                </ul>

              </div>
            </CardSubtitle>
          </div>
          <GatsbyImage className="card-image-top card-image" image={getImage(post.image.childImageSharp.gatsbyImageData)} alt="Description of the image" />

          <div style={{ paddingTop: "30px" }}>
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
          </div>

        </CardBody>
      </Card>
      <h6 className="text-center">If you liked this post, consider sharing it with your friends!</h6>
      <div className="text-center social-share-links">
        <ul className="nonstyledlist">
          <li><a href={'https://www.facebook.com/sharer/sharer.php?u=' + baseUrl + '/post/' + pageContext.slug} className="facebook" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook fa-2x"></i></a></li>
          <li><a href={'https://twitter.com/share?url=' + baseUrl + pageContext.slug + '&text=' + post.title + '&viatwitterhandle'} className="twitter" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter fa-2x"></i></a></li>
        </ul>
      </div>
      {/*<DiscussionEmbed shortname={disqusShortname} config={disqusConfig}  /> */}
      <PageWithComments />

    </Layout>

  )
}

export const postQuery = graphql`
  query blogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        
        author
        date(formatString: "MMM Do YYYY")
        tags
        seo
        image {
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      excerpt
      fields {
        slug
      }
    }
  }
`

export default SinglePost
